import React, { useState, useRef, useEffect } from 'react'
import './KilometerForm.css'
import InputField from '../InputField/InputField'
import SelectField from '../InputField/SelectField'
import Modal from '../Modal/Modal'
import useGraphData from '../../hooks/graph'
import { companyRecipients } from '../../data/companyRecipients'
import { generateKilometerPDF } from '../../utils/pdfHandler'
import sendEmail from '../../utils/emailHandler'

const KilometerForm = () => {
    const { userName, mail } = useGraphData()
    const [name, setName] = useState('')
    const [recipient, setRecipient] = useState('')
    const [trips, setTrips] = useState([])
    const [savedTrips, setSavedTrips] = useState([])
    const [selectedTrip, setSelectedTrip] = useState('')

    const totalAmount = trips
        .reduce(
            (total, trip) =>
                total + 0.23 * parseFloat(trip.kilometers.replace(',', '.')),
            0
        )
        .toFixed(2)

    const newtripRef = useRef(null)

    const dateToday = new Date().toLocaleDateString('nl-NL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    })

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    const [modalCallback, setModalCallback] = useState(null)
    const [modalCancelCallback, setModalCancelCallback] = useState(null)

    useEffect(
        function setUserName() {
            if (userName) {
                setName(userName)
            }
        },
        [userName]
    )

    useEffect(function getSavedTrips() {
        try {
            const savedTrips = JSON.parse(localStorage.getItem('savedTrips'))
            if (savedTrips) {
                setSavedTrips(savedTrips)
            }
        } catch (error) {
            console.error(
                'Error parsing saved trips from local storage:',
                error
            )
        }
    }, [])

    useEffect(
        function setSavedTrips() {
            localStorage.setItem('savedTrips', JSON.stringify(savedTrips))
        },
        [savedTrips]
    )

    useEffect(() => {
        if (!mail) return

        const domain = mail.split('@')[1]
        switch (domain) {
            case 'newspark.nl':
                setRecipient('rp@newspark.nl')
                break
            case 'd2win.nl':
                setRecipient('administratie@d2win.nl')
                break
            case 'acda-rpa.nl':
                setRecipient('administratie@acda-rpa.nl')
                break
            case 'bluenexus.nl':
                setRecipient('administratie@bluenexus.nl')
                break
            case 'blynkt.nl':
                setRecipient('administratie@blynkt.nl')
                break
            default:
                setRecipient(null)
                break
        }
    }, [mail])

    const handleTripChange = (index, field, value) => {
        const newTrips = [...trips]
        newTrips[index][field] = value

        if (field === 'kilometers') {
            const kilometers = parseFloat(value.replace(',', '.'))
            newTrips[index].total = isNaN(kilometers)
                ? 0
                : (0.23 * kilometers).toFixed(2)
        }

        setTrips(newTrips)
    }

    const addTrip = () => {
        setTrips((prevTrips) => {
            const newTrips = [
                ...prevTrips,
                {
                    id: prevTrips.length + 1,
                    description: '',
                    kilometers: '0',
                    total: 0,
                },
            ]
            return newTrips
        })
    }

    const addSelectedTrip = () => {
        const trip = savedTrips.find((trip) => trip.id === selectedTrip)
        if (trip) {
            const newTrip = {
                ...trip,
                id: trips.length + 1,
                date: '',
            }
            setTrips((prevTrips) => [...prevTrips, newTrip])
        }
    }

    useEffect(
        function scrollToNewTrip() {
            if (newtripRef.current) {
                newtripRef.current.scrollIntoView({ behavior: 'smooth' })
            }
        },
        [trips]
    )

    const removetrip = () => {
        setTrips(trips.slice(0, -1))
    }

    const saveUniqueTrips = (trips, prevSavedTrips) => {
        const uniqueTrips = trips
            .map(({ description, kilometers, total }) => ({
                id: `${description}-${kilometers}`,
                description,
                kilometers,
                total,
            }))
            .filter(
                (newTrip, index, self) =>
                    index ===
                    self.findIndex(
                        (trip) =>
                            trip.description === newTrip.description &&
                            trip.kilometers === newTrip.kilometers
                    )
            )
            .filter(
                (newTrip) =>
                    !prevSavedTrips.some(
                        (savedTrip) =>
                            savedTrip.description === newTrip.description &&
                            savedTrip.kilometers === newTrip.kilometers
                    )
            )
        return [...prevSavedTrips, ...uniqueTrips]
    }

    const removeSavedTrip = () => {
        const updatedSavedTrips = savedTrips.filter(
            (trip) => trip.id !== selectedTrip
        )
        setSavedTrips(updatedSavedTrips)
        localStorage.setItem('savedTrips', JSON.stringify(updatedSavedTrips))
        setSelectedTrip('')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const pdfData = generateKilometerPDF(
            name,
            trips,
            dateToday,
            totalAmount
        )

        const response = await sendEmail(
            name,
            mail,
            recipient,
            totalAmount,
            dateToday,
            null,
            pdfData,
            'kilometer'
        )

        if (response) {
            setSavedTrips((prevSavedTrips) =>
                saveUniqueTrips(trips, prevSavedTrips)
            )

            setTrips([])
            setModalMessage('Kilometerregistratie succesvol verzonden!')
            setModalCallback(null)
            setModalCancelCallback(() => () => setIsModalOpen(false))
            setIsModalOpen(true)
        }
    }

    return (
        <div>
            <h2>Kilometerregistratie</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-section">
                    <InputField
                        label="Naam declarant"
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <SelectField
                        label="BV"
                        type="text"
                        id="bv"
                        value={recipient}
                        onChange={(e) => setRecipient(e.target.value)}
                        required
                        options={companyRecipients}
                    />
                </div>

                <div className="form-section">
                    <SelectField
                        label="Opgeslagen rit:"
                        id="savedTrips"
                        value={selectedTrip}
                        onChange={(e) => setSelectedTrip(e.target.value)}
                        options={savedTrips.map((trip) => ({
                            value: trip.id,
                            label: `${trip.description} - ${trip.kilometers} km`,
                        }))}
                    />
                    <button type="button" onClick={addSelectedTrip}>
                        Voeg geselecteerde rit toe
                    </button>
                    <button type="button" onClick={removeSavedTrip}>
                        Verwijder opgeslagen rit
                    </button>
                </div>

                {trips.map((trip, index) => (
                    <div
                        key={trip.id}
                        ref={index === trips.length - 1 ? newtripRef : null}
                        className="form-section"
                    >
                        <h3>Rit {index + 1}</h3>
                        <InputField
                            label="Datum"
                            type="date"
                            id={`date${index}`}
                            value={trip.date}
                            onChange={(e) =>
                                handleTripChange(index, 'date', e.target.value)
                            }
                            required
                        />
                        <InputField
                            label="Omschrijving rit"
                            type="text"
                            id={`description${index}`}
                            value={trip.description}
                            onChange={(e) =>
                                handleTripChange(
                                    index,
                                    'description',
                                    e.target.value
                                )
                            }
                            required
                            maxLength={50}
                        />

                        <InputField
                            label="Aantal km"
                            type="text"
                            id={`kilometers${index}`}
                            value={trip.kilometers.toString().replace('.', ',')}
                            onChange={(e) => {
                                handleTripChange(
                                    index,
                                    'kilometers',
                                    e.target.value.replace(',', '.')
                                )
                            }}
                            required
                            allowedChars={'0-9.,'}
                        />
                        <div className="compensation">
                            <span>Kilometervergoeding: €0.23</span>
                            <span>
                                Totaal rit: €
                                {(
                                    0.23 *
                                    parseFloat(
                                        trip.kilometers.replace(',', '.')
                                    )
                                ).toFixed(2)}
                            </span>
                        </div>
                    </div>
                ))}

                <span className="total">
                    Totale Kilometervergoeding: €{totalAmount}
                </span>

                {trips.length > 1 && (
                    <button type="button" onClick={removetrip}>
                        Haal laatste rit weg
                    </button>
                )}
                {trips.length < 10 && (
                    <button type="button" onClick={addTrip}>
                        Voeg extra rit toe
                    </button>
                )}

                <button type="submit">Opsturen</button>
            </form>
            <Modal
                isOpen={isModalOpen}
                message={modalMessage}
                onConfirm={modalCallback}
                onCancel={modalCancelCallback}
            />
        </div>
    )
}

export default KilometerForm
