import React, { useState } from 'react'
import './InputField.css'

const InputField = ({
    label,
    type,
    id,
    value,
    placeholder,
    onChange,
    required,
    disabled,
    maxLength,
    allowedChars,
}) => {
    const [error, setError] = useState('')

    const validateInput = (value) => {
        if (!allowedChars) return true // If no allowedChars prop is provided, allow all characters
        const regex = new RegExp(`^[${allowedChars}]*$`)
        return regex.test(value)
    }

    const handleChange = (e) => {
        const { value } = e.target
        if (!validateInput(value)) {
            setError(
                `Invoer mag alleen de volgende tekens bevatten: ${allowedChars}`
            )
            return
        }

        if (value.length > maxLength) {
            setError(`Invoer mag niet langer dan ${maxLength} tekens zijn`)
        } else {
            setError('')
            onChange(e)
        }
    }

    return (
        <div className="input-group">
            <label htmlFor={id}>
                {label} {required && <span className="required">*</span>}
            </label>
            <input
                required={required}
                type={type}
                id={id}
                value={value}
                onChange={handleChange}
                disabled={disabled}
                placeholder={placeholder}
            />
            {error && <span className="error-message">{error}</span>}
        </div>
    )
}

export default InputField
