import { graphConfig, loginRequest } from '../authConfig'
import { useMsal } from '@azure/msal-react'
import { useState, useEffect } from 'react'

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers()
    const bearer = `Bearer ${accessToken}`

    headers.append('Authorization', bearer)

    const options = {
        method: 'GET',
        headers: headers,
    }

    return fetch(graphConfig.graphMeEndpoint, options)
        .then((response) => response.json())
        .catch((error) => console.log(error))
}

const useGraphData = () => {
    const { instance, accounts } = useMsal()
    const [userName, setUserName] = useState(null)
    const [mail, setEmail] = useState(null)

    const fetchGraphData = async () => {
        const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        })

        const data = await callMsGraph(response.accessToken)
        setUserName(data.displayName)
        setEmail(data.mail)
    }

    useEffect(() => {
        fetchGraphData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // Empty dependency array means this effect runs once on mount

    return { userName, mail }
}

export default useGraphData
