import React, { useState } from 'react'
import logo from '../../assets/logo-transparent.png'
import './App.css'
import DeclaForm from '../DeclaForm/DeclaForm'
import KilometerForm from '../KilometerForm/KilometerForm'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react'
import { LoginPage } from '../Login/LoginPage'
import { useIsAuthenticated } from '@azure/msal-react'
import { SignOutButton } from '../Login/SignOutButton'
import { Button } from 'react-bootstrap'

const MainContent = () => {
    const [showKilometerForm, setShowKilometerForm] = useState(false)

    const toggleForm = () => {
        setShowKilometerForm(!showKilometerForm)
    }

    return (
        <div className="App">
            <AuthenticatedTemplate>
                <div className="App">
                    <header className="App-header">
                        <Button onClick={toggleForm}>
                            {showKilometerForm
                                ? 'Declaraties'
                                : 'Kilometerregistratie'}
                        </Button>
                        {showKilometerForm ? <KilometerForm /> : <DeclaForm />}
                    </header>
                </div>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">
                    Log in om de declaratie-app te gebruiken
                </h5>
            </UnauthenticatedTemplate>
        </div>
    )
}

export default function App() {
    const isAuthenticated = useIsAuthenticated()

    return (
        <div className="App">
            <img src={logo} className="App-logo" alt="logo" />

            {isAuthenticated && (
                <div className="logout-button-container">
                    <SignOutButton />
                </div>
            )}
            {isAuthenticated ? <MainContent /> : <LoginPage />}
        </div>
    )
}
